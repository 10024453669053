<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <el-button type="primary" plain size="mini" v-if="routePlanningSearchFlag" @click="routePlanningSearch">查询</el-button>
                        <el-button type="primary" plain size="mini" v-if="routePlanningAddFlag" @click="routePlanningAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="routePlanningModifyFlag" @click="routePlanningModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="routePlanningDelFlag" @click="routePlanningDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入收货方线路" v-model="dataForms.vendeeLine" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog_seach = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="线路高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog_seach"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="收货方名称" prop="vendeeName">
                                                    <el-input   v-model="dataForms.vendeeName" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                                                </el-form-item>
                                                <el-form-item label="收货方地址" prop="vendeeAddress">
                                                    <el-input   v-model="dataForms.vendeeAddress" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <el-button size="mini" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogtitile"
                :visible.sync="dialog_vehicleData"
                width="75%"
                :modal="true"
                center
                :before-close="handleClose_vehicleData">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">线路信息</span>
                </div>
                <div>
                    <el-form :model="vehicleDataForm"  ref="vehicleDataForm" :rules="vehicleDataFormrules" label-width="100px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="收货方名称" prop="vendeeName">
                                    <el-input v-model="vehicleDataForm.vendeeName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="收货方电话" prop="vendeeMobile">
                                    <el-input v-model="vehicleDataForm.vendeeMobile"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="线路分类" prop="moundFlag">
                                    <el-select v-model="vehicleDataForm.moundFlag" placeholder="请选择">
                                        <el-option label="市内" value="0"></el-option>
                                        <el-option label="外埠" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="收货方线路" prop="vendeeLine">
                                    <el-input v-model="vehicleDataForm.vendeeLine"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <el-form-item label="收货方地址" prop="vendeeAddress">
                                    <el-input v-model="vehicleDataForm.vendeeAddress"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_vehicleData">提 交</el-button>
                <el-button @click="handleClose_vehicleData">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import axios from 'axios'
    import qs from 'qs'
    import arrayQuery from '../../components/arrayQuery.js'
    import 'element-ui/lib/theme-chalk/index.css'
    import flightTable from "../../components/common/flightTable.vue"
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialog_seach:false,
                dialog_vehicleData:false,
                dialogtitile:'',
                options:[],
                tableDatadialog: [],
                columns: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "280",
                        prop: "carriersId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "280",
                        prop: "carriersName",
                        isShow: true,
                        align: "center"
                    }
                ],
                tableDatadialog_pagination:{
                    pageSize:5,
                    pageNum:1,
                    total:0
                },
                pagesizes: [5, 10, 15, 20],
                dataForms:{
                    vendeeName:'',
                    vendeeAddress:'',
                    vendeeLine:''
                },
                vehicleDataForm:{
                    id:'',
                    vendeeName: '',
                    vendeeAddress:'',
                    moundFlag:'',
                    vendeeLine:'',
                    vendeeMobile:''
                },
                vehicleDataFormrules: {
                    vendeeName: [
                        { required: true,message: '请输入收货方名称', trigger: 'blur' },
                    ],
                    vendeeAddress: [
                        { required: true,message: '请输入收货方地址', trigger: 'blur'}
                    ],
                    vendeeMobile:[
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    moundFlag: [
                        { required: true,message: '请输入线路分类', trigger: 'blur' }
                    ],
                    vendeeLine: [
                        { required: true,message: '请输入收货方线路', trigger: 'blur'}
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'收货方名称',prop:'vendeeName',width:'160',align:'center'},
                    {label:'收货方地址',prop:'vendeeAddress',width:'160',align:'center'},
                    {label:'收货方手机',prop:'vendeeMobile',align:'center'},
                    {label:'收货方线路',prop:'vendeeLine',align:'center'},
                    {label:'创建人员',prop:'creator',align:'center',width:'120'},
                    {label:'时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                merge_carrier:[],
                routePlanningSearchFlag:true,
                routePlanningAddFlag:true,
                routePlanningModifyFlag:true,
                routePlanningDelFlag:true

            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            flightTable
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_3_6';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let routePlanningSearch = currentPageButtons.find(item => {
                return item.menuId == '2_3_6_1';
            });
            if(routePlanningSearch == undefined){
                _this.routePlanningSearchFlag = false;
            }else {
                _this.routePlanningSearchFlag = true;
            }

            //新增按钮权限
            let routePlanningAdd = currentPageButtons.find(item => {
                return item.menuId == '2_3_6_2';
            });
            if(routePlanningAdd == undefined){
                _this.routePlanningAddFlag = false;
            }else {
                _this.routePlanningAddFlag = true;
            }
            //修改按钮权限
            let routePlanningModify = currentPageButtons.find(item => {
                return item.menuId == '2_3_6_3';
            });
            if(routePlanningModify == undefined){
                _this.routePlanningModifyFlag = false;
            }else {
                _this.routePlanningModifyFlag = true;
            }
            //删除按钮权限
            let routePlanningDel = currentPageButtons.find(item => {
                return item.menuId == '2_3_6_4';
            });
            if(routePlanningDel == undefined){
                _this.routePlanningDelFlag = false;
            }else {
                _this.routePlanningDelFlag = true;
            }
        },
        methods:{
            routePlanningSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.routePlanningSearch();
            },
            handleClose() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.routePlanningSearch_Multiple();
                    // 动画关闭需要一定的时间
                    this.dialog_seach = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog_seach = false;
                clearTimeout(this.timer);
            },
            save_vehicleData(){
                this.$refs["vehicleDataForm"].validate(valid => {
                    if (valid) {
                        if( this.dialogtitile == '新增收货方信息'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/urban/line/addLine`,
                                data:this.vehicleDataForm
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_vehicleData();
                                    this.routePlanningSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                }
                            });
                        }else if(this.dialogtitile == '修改收货方信息'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/urban/line/updateLine`,
                                data:this.vehicleDataForm
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_vehicleData();
                                    this.routePlanningSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },
            handleClose_vehicleData(){
                this.dialog_vehicleData = false;
                this.$refs.vehicleDataForm.resetFields();
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.routePlanningSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.routePlanningSearch()
            },
            handleQuery(){ //查询
                //快捷查询
                this.routePlanningSearch_Multiple()
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            routePlanningSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('vendeeName', this.dataForms.vendeeName);
                data.append('vendeeAddress', this.dataForms.vendeeAddress);
                data.append('vendeeLine', this.dataForms.vendeeLine);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/line/queryLine`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            routePlanningSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.routePlanningSearch();
            },
            //新增
            routePlanningAdd(){
                this.dialogtitile = '新增收货方信息';
                this.dialog_vehicleData = true;
                //this.$router.replace({ path: "/vehicleAdd" });
            },
            //修改
            routePlanningModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialogtitile = '修改收货方信息';
                    this.dialog_vehicleData = true;
                    const id = new URLSearchParams();
                    id.append('id', this.merge[0].id);
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/line/getLine`,
                        data:id
                    }).then(res=> {
                        res.data.data.moundFlag = JSON.stringify(res.data.data.moundFlag);
                        this.vehicleDataForm = res.data.data;
                    });
                }
            },
            //删除
            routePlanningDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对收货方信息进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        const waybilldata = new URLSearchParams();
                        waybilldata.append('id', this.merge[0].id);
                        axios({
                            method: 'post',
                            url: `${this.$apiPath}/api/urban/line/delLine`,
                            data:waybilldata
                        }).then(res=> {
                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.routePlanningSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });

                            }
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
    .waybill_add .ces-table{
        height: 200px;
    }
    .waybill_add .el-table th{
        padding: 0;
        height: 30px;
    }
    .waybill_add .el-table td{
        padding: 0;
        height: 30px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
